<template>
  <div class="sp-card">
    <div class="sp-card__icon">
      <BaseImage
        v-if="icon"
        :src="icon" />
      <BaseImage
        v-else
        src="@/assets/images/profile_placeholder.svg" />
      <!-- <BaseIcon glyph="brand" class="p-card__icon-placeholder" /> -->
    </div>
    <div class="sp-card__data">
      <div class="sp-card__type">{{ profileType }}</div>
      <router-link
        v-if="linkTo"
        :to="linkTo"
        class="sp-card__title sp-card__link"
        >{{ title }}
      </router-link>
      <div
        v-else
        class="sp-card__title">
        {{ title }}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallProfileCard",
  props: {
    title: {
      type: String,
    },
    icon: {
      type: String,
    },
    // Спецпроект/Профиль
    profileType: {
      type: String,
    },
    linkTo: {
      type: [String, Object],
    },
  },
};
</script>

<style lang="less" scoped>
.sp-card {
  background-color: #fff;
  overflow: hidden;
  border-radius: @radius-xl;
  box-shadow: @shadow-default;
  padding: 16px 24px;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: @tropical-blue;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 10px;
    position: relative;
    overflow: hidden;
  }

  &__type {
    .fz(12);
    color: @secondary-color;
  }

  &__title {
    display: block;
    .fz(16);
    font-weight: bold;
    line-height: 1.1;
    color: inherit;
    text-decoration: none;
    transition: color 0.3s;
    &:hover {
      color: @link-color;
    }
  }

  &__link {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
