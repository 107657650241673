<template>
  <div>
    <IndexSlider
      class="mb-l"
      :slides="slides"
      :pending="bannersPending" />
    <section class="mb-m mt-m">
      <h1 class="text-size-h1">Привет, {{ user.first_name }}!</h1>
    </section>
    <StageStatusPanel class="mb-xl" />
    <div
      v-if="!isJunior"
      class="panel radius-xl mb-xl">
      <BindYandex />
    </div>

    <EducationBlocks
      v-if="educationBlocks.length && !isJunior"
      :blocks="educationBlocks"
      class="mb-xl" />

    <section
      v-if="stageActivities.length"
      class="mb-xl">
      <div class="mb-s">
        <h3 class="text-size-h3 text-bold">Ближайшее расписание</h3>
      </div>
      <div>
        <ActivityCard
          v-for="item in stageActivities"
          :key="item.id"
          :activity="item"
          class="mb-xs"
          :score="
            activityScores ? activityScores[item.id] : null
          "></ActivityCard>
      </div>
    </section>

    <EducationBlocks
      v-if="educationBlocks.length && isJunior"
      :blocks="educationBlocks"
      class="mb-xl" />

    <section class="mb-xl">
      <div class="mb-s">
        <h3 class="text-size-h3 text-bold">Этап 1</h3>
        <div v-if="isStudent">Командный</div>
        <div v-else>Индивидуальный</div>
      </div>

      <div v-if="firstStageProfiles.length">
        <div class="row cards-list">
          <div
            v-for="profile in firstStageProfiles"
            :key="profile.id"
            class="col-sm-6 col-xs-12 cards-list__item">
            <SmallProfileCard
              class="cards-list__card"
              :title="profile.title"
              :icon="profile.icon"
              :profile-type="profile.profileType"
              :link-to="{
                name: 'user-profile-stage-1',
                params: { profile_id: profile.id },
              }">
              <div
                v-if="profile.scores"
                class="prof-scores">
                <ScoreInfo
                  v-for="(item, idx) in profile.scores"
                  :key="idx"
                  :score="item.score"
                  :track="item.track"
                  :updated="item.updated_at"
                  class="mb-xs" />
              </div>
              <div
                v-else
                class="color-meta text-size-s mt-s">
                Нет данных по набранным баллам
              </div>
            </SmallProfileCard>
          </div>
        </div>
      </div>

      <div v-else>
        <p v-if="isJunior">
          Выбери сферу, в которой планируешь участвовать<br />
          <router-link
            :to="{ name: 'user-profiles' }"
            class="link"
            >Выбрать сферу</router-link
          >
        </p>
        <p v-else>
          Выбери профили и&nbsp;спецпроекты в&nbsp;которых ты&nbsp;будешь
          участвовать
          <router-link
            :to="{ name: 'user-profiles' }"
            class="link"
            >Выбрать профили</router-link
          >
        </p>
      </div>
    </section>

    <section
      v-if="secondStageProfiles && secondStageProfiles.length"
      class="mb-xl">
      <div
        v-if="isStudent || isJunior"
        class="mb-s">
        <h3 class="text-size-h3 text-bold">Финал</h3>
      </div>
      <div
        v-else
        class="mb-s">
        <h3 class="text-size-h3 text-bold">Этап 2</h3>
        <div>Командный</div>
      </div>
      <div class="row cards-list">
        <div
          v-for="profile in secondStageProfiles"
          :key="profile.id"
          class="col-sm-6 col-xs-12 cards-list__item">
          <SmallProfileCard
            class="cards-list__card"
            :title="profile.title"
            :icon="profile.icon"
            :profile-type="profile.profileType"
            :link-to="{
              name: 'user-profile-stage-2',
              params: { profile_id: profile.id },
            }">
            <div
              v-if="profile.scores"
              class="prof-scores">
              <ScoreInfo
                v-for="(item, idx) in profile.scores"
                :key="idx"
                :score="item.score"
                :track="item.track"
                :updated="item.updated_at"
                class="mb-xs" />
            </div>
            <div
              v-else
              class="color-meta text-size-s mt-s">
              Нет данных по набранным баллам
            </div>
          </SmallProfileCard>
        </div>
      </div>
    </section>

    <section
      v-if="thirdStage && thirdStageProfiles && thirdStageProfiles.length"
      class="mb-xl">
      <div class="mb-s">
        <h3 class="text-size-h3 text-bold">Финал</h3>
      </div>
      <div class="row cards-list">
        <div
          v-for="profile in thirdStageProfiles"
          :key="profile.id"
          class="col-sm-6 col-xs-12 cards-list__item">
          <SmallProfileCard
            class="cards-list__card"
            :title="profile.title"
            :icon="profile.icon"
            :profile-type="profile.profileType"
            :link-to="{
              name: 'user-profile-stage-3',
              params: { profile_id: profile.id },
            }">
          </SmallProfileCard>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BindYandex from "@/components/BindYandex";
import SmallProfileCard from "@/components/profile/SmallProfileCard";
import ActivityCard from "@/components/profile/ActivityCard";
import IndexSlider from "@/components/index-slider/IndexSlider.vue";
import ScoreInfo from "@/components/profile/ScoreInfo";
import StageStatusPanel from "@/components/user/StageStatusPanel";
import {
  DRUGOE_DELO_PARTNER_ID,
  IGNORE_MENTOR_RATINGS_PREFIX,
  INDIVIDUAL_STAGE,
  MODAL_DYNAMIC_DEFAULTS,
  TEAM_STAGE,
} from "@/constants";
import MentorRating from "@/components/user/modals/MentorRating";
import { request } from "@/services/api";
import { getCookie } from "@/utils/cookies";
import dayjs from "@/plugins/dayjs";
import CorrectnessPersonalDataModal from "@/components/user/modals/CorrectnessPersonalDataModal.vue";
import EducationBlocks from "@/components/education/EducationBlocks.vue";

const CHECK_PD_MODAL_UNTIL_DATE = "2023-12-17";

const getNotEndedActivities = (n) =>
  n.start_at && n.end_at && dayjs(n.end_at).isAfter(dayjs());

const CORRECTNESS_PERSONAL_DATA_MODAL_SHOWN = "correctness_personal_data_modal";
export default {
  name: "UserIndex",
  components: {
    BindYandex,
    SmallProfileCard,
    ActivityCard,
    IndexSlider,
    ScoreInfo,
    StageStatusPanel,
    EducationBlocks,
  },

  metaInfo() {
    return {
      title: "Главная страница участника",
    };
  },
  data() {
    return {
      banners: [],
      bannersPending: false,
      isPartner: false,
    };
  },
  computed: {
    ...mapState({
      profiles: (state) => state.profile.profiles,
      privileges: (state) => state.participant.privileges,
      scores: (state) => state.participant.scores,
      tracks: (state) => state.tracks,
      teams: (state) => state.participant.teams,
    }),
    ...mapGetters({
      selectedList: "participant/selectedList",
      teamStageList: "participant/teamStageList",
      finalStageList: "participant/finalStageList",
      user: "user/user",
      isJunior: "participant/isJunior",
      isStudent: "participant/isStudent",
      activityScores: "participant/activityScores",
      firstStage: "participant/firstStage",
      thirdStage: "participant/thirdStage",
      secondStage: "participant/secondStage",
      labels: "participant/profileLabels",
      notUsedPrivileges: "participant/notUsedPrivileges",
      individualProfilesScores: "participant/individualProfilesScores",
    }),
    /**
     * Баллы за профильи первого этапа
     */
    firstStageProfileScores() {
      const { firstStage, scores, tracks } = this;
      if (!scores?.profile_scores?.length) return {};
      if (firstStage.stage === INDIVIDUAL_STAGE) {
        return this.individualProfilesScores;
      }
      return scores.profile_scores.reduce((acc, value) => {
        if (value.stage === firstStage.stage && value.score) {
          if (!acc[value.profile_id]) {
            acc[value.profile_id] = [];
          }
          acc[value.profile_id].push({
            track: tracks[value.track_id]?.title,
            track_id: value.track_id,
            score: value.score,
            updated_at: value.updated_at,
          });
        }
        return acc;
      }, {});
    },
    /**
     * Баллы за профильи второго этапа
     */
    secondStageProfileScores() {
      const { secondStage, scores, tracks } = this;
      if (!scores?.profile_scores?.length) return {};
      return scores.profile_scores.reduce((acc, value) => {
        if (value.stage === secondStage.stage && value.score) {
          if (!acc[value.profile_id]) {
            acc[value.profile_id] = [];
          }
          acc[value.profile_id].push({
            track: tracks[value.track_id]?.title,
            track_id: value.track_id,
            score: value.score,
            updated_at: value.updated_at,
          });
        }
        return acc;
      }, {});
    },
    firstStageProfiles() {
      const { selectedList, profiles, firstStageProfileScores, labels } = this;
      return selectedList.reduce((acc, value) => {
        const p = profiles[value.profile_id];
        if (p) {
          const profileType = p.is_special_project
            ? labels.special.name
            : labels.profile.name;
          acc.push({
            ...p,
            profileType,
            scores: firstStageProfileScores[value.profile_id],
          });
        }
        return acc;
      }, []);
    },
    slides() {
      const { notUsedPrivileges, banners } = this;
      let result = [];
      if (notUsedPrivileges?.length) {
        notUsedPrivileges.forEach((n) => {
          const slide = {
            type: "privilege",
            title: "Привилегия",
            score: n.score,
            privilege: n.id,
            pass: !!n.pass_through,
            finalPass: n.final_pass,
            id: `user-priv-${n.id}`,
          };
          // если привилегия про баллы
          if (n.score) {
            slide.title = "Дополнительные баллы";
          } else if (n.pass_through) {
            slide.title = "Переход в следующий этап";
          } else if (n.final_pass) {
            slide.title = "Пропуск в финал";
          }
          result.push(slide);
        });
      }

      if (banners?.length) {
        result = [
          ...result,
          ...banners.map((n) => {
            return {
              type: "banner",
              ...n,
            };
          }),
        ];
      }

      const requireDrugoeDeloBanner =
        process.env.VUE_APP_PARTNER_BANNER_ENABLED == "1" &&
        !this.isStudent &&
        !this.isPartner;

      // Добавляем партнерский баннер если не запартнерились
      if (requireDrugoeDeloBanner) {
        result.push({
          description:
            "Участвовать в НТО как обычно и получать за это баллы на свою почту, которые можно обменять на классные подарки?<br />Да — с проектом «Другое Дело»!",
          id: "drugoe-delo-partner",
          img: require("@/assets/images/drugoe_delo.png"),
          published: true,
          title: "Получай баллы в проекте «Другое Дело»!",
          type: "partner",
          buttonText: "Хочу присоединиться!",
        });
      }

      return result;
    },
    stageActivities() {
      const { firstStage, secondStage, thirdStage } = this;
      const firstStageList = this.$store.getters[
        "participant/activitiesByStage"
      ](firstStage.stage);
      const secondStageList = this.$store.getters[
        "participant/activitiesByStage"
      ](secondStage?.stage);
      const thirdStageList = this.$store.getters[
        "participant/activitiesByStage"
      ](thirdStage?.stage);
      // По умолчанию вернем список активностей первого этапа
      let result = [...firstStageList];

      // Если началася второй этап, и у пользователя есть активности
      // второго этапа, то вернем список активностей второго
      // этапа + список незавершенных активностей, первого этапа
      if (secondStage && secondStage.isStarted && secondStageList.length) {
        result = [...result.filter(getNotEndedActivities), ...secondStageList];
      }
      // Если начался 3 этап, и у пользователя есть активности
      // третьего этапа то вернем список активностей третьего этапа
      // + список незавершенных активностей предыдущих этапов
      if (thirdStage && thirdStage.isStarted && thirdStageList.length) {
        result = [...result.filter(getNotEndedActivities), ...thirdStageList];
      }
      return result;
    },
    secondStageProfiles() {
      const {
        teamStageList,
        isJunior,
        isStudent,
        labels,
        profiles,
        secondStageProfileScores,
        finalStageList,
      } = this;

      let list;
      if (isJunior || isStudent) {
        list = finalStageList;
      } else {
        list = teamStageList;
      }
      return list.reduce((acc, value) => {
        const p = profiles[value.profile_id];
        if (p) {
          const profileType = p.is_special_project
            ? labels.special.name
            : labels.profile.name;
          acc.push({
            ...p,
            profileType,
            failed: value.failed,
            scores: secondStageProfileScores[value.profile_id],
          });
        }
        return acc;
      }, []);
    },
    thirdStageProfiles() {
      const { thirdStage, finalStageList, profiles, labels } = this;
      if (!thirdStage) return [];
      return finalStageList.reduce((acc, value) => {
        const p = profiles[value.profile_id];
        if (p) {
          const profileType = p.is_special_project
            ? labels.special.name
            : labels.profile.name;
          acc.push({
            ...p,
            profileType,
          });
        }
        return acc;
      }, []);
    },
    correctnessModalKey() {
      return `${CORRECTNESS_PERSONAL_DATA_MODAL_SHOWN}_${this.user.id}`;
    },

    educationBlocks() {
      const list = this.$store.state.participant.edublocks;
      const profiles =
        this.$store.getters["participant/selectedList"]?.map(
          (n) => n.profile_id
        ) || [];
      if (!list?.length) return [];
      return list.filter((n) => {
        return (
          !n.profiles?.length || n.profiles.some((id) => profiles.includes(id))
        );
      });
    },
  },
  async created() {
    await this.$store.dispatch("participant/getScores");
  },
  mounted() {
    this.mentorRatingRequest();
    this.initParticipantData();
    this.getBanners();
    this.conditionallyShowInfoModal();
    this.$store.dispatch("participant/getEduBlocks");
  },
  methods: {
    /**
     * По завершению этапа,
     * если у юзера есть наставники, и он их еще не оценивал
     * нужно показать модалку с оценками наставников.
     */
    async mentorRatingRequest() {
      const { firstStageProfiles, firstStage } = this;
      const isIgnore = getCookie(
        `${IGNORE_MENTOR_RATINGS_PREFIX}${this.user.talent_id}`
      );
      // если юзер нажал игнорировать рейтинги
      // или нет профилей, или первый стейдж не завершен
      // то выкидываем из функции
      if (isIgnore || !firstStageProfiles?.length || !firstStage.isEnded) {
        return;
      }
      try {
        // 1. Сначала получим список наставников
        const mentors = await this.$store.dispatch("participant/getMentors");
        let mentorsList = Object.values(mentors);
        // Если наставников нет - то и оценивать некого
        if (!mentorsList.length) return;
        // 2. Получим список оценок, если оценили не всех наставников
        // то покажем модалку
        const { data } = await request({
          method: "GET",
          url: "/mentor/ratings",
        });
        if (data?.items) {
          // уберем меторов которые уже имеют оценки
          const ids = data.items.map((n) => n.mentor_id);
          mentorsList = mentorsList.filter((n) => !ids.includes(n.id));
        }
        // Если уже оценили всех наставников
        if (!mentorsList.length) return;
        this.$modal.show(
          MentorRating,
          {
            mentors: mentorsList,
            stageNumber: 1,
            stage: firstStage.stage,
            profiles: firstStageProfiles.map((n) => {
              return {
                id: n.id,
                title: n.title,
              };
            }),
          },
          MODAL_DYNAMIC_DEFAULTS
        );
      } catch (error) {
        // do nothing
      }
    },
    async initParticipantData() {
      const {
        firstStage,
        firstStageProfiles,
        secondStage,
        secondStageProfiles,
      } = this;
      if (!firstStageProfiles?.length) return;
      const teamRequests = [];
      const scheduleRequest = [];

      // получаем команды и расписание профилей
      this.firstStageProfiles.forEach((n) => {
        if (firstStage.stage === TEAM_STAGE) {
          teamRequests.push(this.$store.dispatch("participant/getTeam", n.id));
        }
        scheduleRequest.push(
          this.$store.dispatch("profile/getProfileSchedule", n.id)
        );
      });

      if (
        secondStage &&
        secondStageProfiles?.length &&
        secondStage.stage === TEAM_STAGE
      ) {
        this.secondStageProfiles.forEach((n) => {
          teamRequests.push(this.$store.dispatch("participant/getTeam", n.id));
          // может оказаться так, что у юзера нет этого профиля в выбранных
          // зато есть в профилях командного этапа
          scheduleRequest.push(
            this.$store.dispatch("profile/getProfileSchedule", n.id)
          );
        });
      }

      try {
        teamRequests.length && (await Promise.all(teamRequests));
      } catch (error) {
        this.showErrorModal({
          title: "Не удалось получить данные о команде",
          message: error.message,
        });
      }
      try {
        scheduleRequest.length && (await Promise.all(scheduleRequest));
      } catch (error) {
        this.showErrorModal({
          title: "Не удалось получить расписание профиля",
          message: error.message,
        });
      }
    },
    conditionallyShowInfoModal() {
      /*для участников второго этапа нужно показать модалку
        с проверкой личных данных если
        незафейлен второй этап, не студент и ранее не показывали
        */
      const { secondStageProfiles, isStudent, correctnessModalKey } = this;
      const isDatePassed = dayjs().isAfter(dayjs(CHECK_PD_MODAL_UNTIL_DATE));
      if (isDatePassed || isStudent) return;
      const isSecondStageProfilesPassed = secondStageProfiles.some(
        (itm) => !itm.failed
      );
      const isModalShown = localStorage?.getItem(correctnessModalKey);

      if (isSecondStageProfilesPassed && !isModalShown) {
        this.$modal.show(
          CorrectnessPersonalDataModal,
          {},
          { ...MODAL_DYNAMIC_DEFAULTS, classes: ["v--modal", "no-overflow"] },
          { "before-close": this.setModalIsShown }
        );
      }
    },
    setModalIsShown() {
      localStorage?.setItem(this.correctnessModalKey, "1");
    },
    /**
     * Получаем список баннеров
     * по всему треку
     */
    async getBanners() {
      this.bannersPending = true;
      try {
        const data = await this.$store.dispatch("getBanners");
        this.banners = data.items;
      } catch (error) {
        // do nothing
      }
      try {
        await this.$store.dispatch("participant/getPartners");
        this.isPartner = this.$store.state.participant.partners.some(
          (n) => n.id === DRUGOE_DELO_PARTNER_ID
        );
      } catch (error) {
        // do nothing
      }

      this.bannersPending = false;
    },
  },
};
</script>

<style lang="less" scoped>
.prof-scores {
  margin-top: 5px;
  font-size: 14px;
}
</style>
