<template>
  <div class="mr-card">
    <div class="mr-card__mentor">
      <BaseAvatar
        class="mr-card__mentor-icon"
        :image="mentor.avatar" />
      <div class="mr-card__mentor-name">{{ mentor.fullName }}</div>
    </div>
    <div
      v-if="ratings.noSupport"
      class="rating-object">
      <p>Наставник никак не помогал</p>
      <a
        href="#"
        class="link link--pseudo"
        @click.prevent="handleSetNotSupport(false)"
        >Удалить оценку</a
      >
    </div>
    <div v-if="!ratings.noSupport">
      <div
        v-for="rating in userRatings"
        :key="rating.profile"
        class="mb-m rating-object">
        <BaseInput
          type="text"
          class="mb-s"
          readonly
          :value="rating.title">
          <template #append>
            <button
              title="Удалить оценку"
              type="button"
              class="rating-remove"
              @click.prevent="handleRemoveRating(rating.profile)">
              <BaseIcon glyph="close" />
            </button>
          </template>
        </BaseInput>
        <div class="rating-options">
          <button
            v-for="option in ratingOptions"
            :key="`rating-option-${option.value}`"
            class="rating-options__item"
            type="button"
            :class="{ 'is-active': rating.rating === option.value }"
            @click="handleUpdateRating(rating.profile, option.value)">
            {{ option.title }}
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="ratingFromVisibility"
      class="rating-object mb-m">
      <div class="mb-s">
        <ValidationProvider
          v-slot="{ errors }"
          :rules="selectRequired">
          <BaseSelect
            v-model="selectedProfile"
            :options="allowedProfiles"
            :searchable="false"
            :show-no-results="false"
            :preselect-first="false"
            label="title"
            :allow-empty="true"
            placeholder="Выбрать профиль"></BaseSelect>
          <div
            v-if="errors.length"
            class="form-error">
            Необходимо указать профиль, или отметить, что наставник не помогал
          </div>
        </ValidationProvider>
      </div>
      <div class="rating-options">
        <button
          v-for="option in ratingOptions"
          :key="`option-${option.value}`"
          :disabled="!selectedProfile && !!option.value"
          class="rating-options__item"
          @click="handleSetRating(selectedProfile, option.value)">
          {{ option.title }}
        </button>
        <ValidationProvider
          v-slot="{ errors }"
          :rules="optionRules">
          <input
            type="hidden"
            value="" />
          <div
            v-if="errors.length"
            class="form-error">
            Необходимо выбрать одну из опций
          </div>
        </ValidationProvider>
      </div>
    </div>
    <div
      v-if="showAddMoreButton"
      class="rating-footer">
      <a
        class="rating-more"
        href="#"
        @click.prevent="showForm = true"
        >+ Добавить еще 1 профиль</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "MentorRatingCard",
  props: {
    mentor: {
      type: Object,
      required: true,
    },
    ratings: {
      type: Object,
      required: true,
    },
    profiles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedProfile: null,
      ratingOptions: [
        {
          value: 2,
          title: "Наставник всегда был на связи и готов помочь подготовиться",
        },
        {
          value: 1,
          title: "Наставник помогал с организационными вопросами",
        },
        {
          value: 0,
          title: "Наставник никак не помогал",
        },
      ],
      showForm: true,
    };
  },
  computed: {
    /**
     * Убираем из списка профили
     * по которым уже есть оценка
     */
    allowedProfiles() {
      const ids = this.userRatings.map((n) => n.profile);
      return this.profiles.filter((n) => !ids.includes(n.id));
    },
    ratingFromVisibility() {
      return (
        this.allowedProfiles.length && !this.ratings?.noSupport && this.showForm
      );
    },
    showAddMoreButton() {
      return (
        !this.showForm && this.allowedProfiles.length && !this.ratings.noSupport
      );
    },
    userRatings() {
      const { ratings } = this;
      if (!ratings?.ratings?.length) return [];
      return ratings.ratings.map((n) => {
        const profile = this.profiles.find((p) => p.id === n.profile);
        return {
          ...n,
          title: profile?.title,
        };
      });
    },
    selectRequired() {
      return {
        required:
          !this.userRatings.length &&
          !this.selectedProfile &&
          !this.ratings.noSupport,
      };
    },
    optionRules() {
      return {
        required: !!this.selectedProfile,
      };
    },
  },
  methods: {
    /**
     * @param {Number} profile - профиль
     * @param {Number} value - оценка
     */
    handleUpdateRating(profile, value) {
      this.$emit("setRating", {
        profile: profile,
        rating: value,
        mentor: this.mentor.talent_id,
      });
    },
    /**
     * @param {Number} profile - профиль
     */
    handleRemoveRating(profile) {
      this.$emit("removeRating", {
        profile: profile,
        mentor: this.mentor.talent_id,
      });
      if (!this.userRatings?.length) {
        this.showForm = true;
      }
    },
    /**
     * @param {Object} profile - профиль
     * @param {Number} value - оценка
     */
    handleSetRating(profile, value) {
      if (value === 0 && !profile) {
        this.handleSetNotSupport(true);
      } else {
        this.$emit("setRating", {
          profile: profile.id,
          rating: value,
          mentor: this.mentor.talent_id,
        });
      }
      this.selectedProfile = null;
      this.showForm = false;
    },
    handleSetNotSupport(status) {
      if (!status) {
        this.showForm = true;
      }
      this.$emit("setNonSupport", {
        mentor: this.mentor.talent_id,
        status: status,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.mr-card {
  padding: 8px;
  background-color: @bg-color;
  border-radius: @radius-m;
  box-shadow: @shadow-default;

  &__mentor {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 10px;

    &-icon {
      width: 42px;
      flex-grow: 0;
      margin-right: 1rem;
    }

    &-name {
      .fz(16);
      font-weight: bold;
    }
  }

  .rating-options {
    display: block;

    &__item {
      display: block;
      margin-bottom: 0.5rem;
      font-family: inherit;
      background-color: transparent;
      padding: 0;
      border: 0;
      .fz(15);
      position: relative;
      padding-left: 1.45em;
      cursor: pointer;
      appearance: none;
      width: 100%;
      text-align: left;

      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 50%;
      }

      &::before {
        width: 1em;
        height: 1em;
        border: 2px solid @input-border-color;
        transform: translateY(-50%);
      }

      &::after {
        width: 0.45em;
        height: 0.45em;
        left: 0.5em;
        background-color: @primary-color;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover::after {
        opacity: 0.5;
      }

      &.is-active::before {
        border-color: @primary-color;
      }
      &.is-active::after {
        opacity: 1;
      }
      &:disabled::after {
        opacity: 0 !important;
      }
      &:disabled::before {
        border-color: @disabled-color;
        opacity: 0.3;
      }
    }
  }

  .rating-object {
    padding: 1rem;
    background-color: #fff;
    border-radius: @radius-m;
    box-shadow: @shadow-default;
  }

  .rating-footer {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    text-align: center;
  }

  .rating-more {
    text-decoration: none;
    display: inline-block;
    background-color: #fff;
    border-radius: 20px;
    padding: 0.2em 0.8em;
    .fz(14);
    color: @link-color;
    box-shadow: @shadow-default;
  }

  .rating-remove {
    appearance: none;
    outline: none;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    .fz(24);
    padding: 0 5px;
    color: fade(@disabled-color, 50%);
    transition: color 0.3s;

    &:hover {
      color: @primary-color;
    }
  }
}
</style>
