<template>
  <BaseModalContent
    :dynamic="true"
    @close="$emit('close')">
    <h2 class="text-bold text-size-h2 mb-m">Проверьте данные профиля</h2>
    <p class="mb-s">
      Обязательно проверьте корректность указанных вами данных. ФИО, школа,
      класс, дата рождения. Если где-то есть неточности, исправьте
      их&nbsp;до&nbsp;1&nbsp;декабря.
    </p>

    <p class="mb-s">
      Данные из&nbsp;личного кабинета используются при подготовке сертификатов
      участников второго отборочного этапа и&nbsp;для формирования списка
      финалистов.
    </p>
    <p class="mb-s">
      Если вы&nbsp;указали некорректные данные и&nbsp;не&nbsp;исправили
      их&nbsp;до&nbsp;1&nbsp;декабря, то&nbsp;в&nbsp;вашем сертификате будут
      ошибки&nbsp;и, с&nbsp;высокой вероятностью, вам не&nbsp;смогут купить
      билеты и&nbsp;оплатить проживание, если вы&nbsp;выйдете в&nbsp;финал.
    </p>
    <p class="mb-s">
      В&nbsp;случае, если у&nbsp;вас возникли вопросы, обратитесь в&nbsp;службу
      поддержки&nbsp;
      <a
        class="link"
        :href="`mailto:${$store.state.supportEmail}`"
        >{{ $store.state.supportEmail }}</a
      >
    </p>
    <div
      v-if="showPersonalBox"
      class="box">
      <p class="mb-xs">Ваши личные данные:</p>
      <ul>
        <li v-if="userFullName">
          <p class="label">ФИО:</p>
          <p>{{ userFullName }}</p>
        </li>
        <li v-if="educationName">
          <p class="label">Школа:</p>
          <p>{{ educationName }}</p>
        </li>
        <li v-if="grade">
          <p class="label">Класс:</p>
          <p>{{ grade }}</p>
        </li>
      </ul>
    </div>
    <div class="buttons">
      <BaseButton @click="goToAccount">Изменить данные</BaseButton>
      <BaseButton
        theme="secondary"
        @click="$emit('close')"
        >Закрыть</BaseButton
      >
    </div>
  </BaseModalContent>
</template>

<script>
export default {
  name: "CorrectnessPersonalDataModal",
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    userFullName() {
      const { user } = this;
      return [user.last_name, user.first_name, user.middle_name].join(" ");
    },
    grade() {
      return this.$store.state.participant.grade || this.user.grade;
    },
    showPersonalBox() {
      return this.userFullName || this.educationName || this.grade;
    },
    educationName() {
      const list = this.$store.state.user.educations;
      const eduId =
        this.$store.state.participant.talent_education_id ||
        this.user.current_education;
      const education = list.find((item) => item.id === eduId);
      return education?.title || education?.full_name;
    },
  },
  methods: {
    goToAccount() {
      window.open(
        `${process.env.VUE_APP_TALENT_BASE_URL}/profile?view=personal_data`,
        "_talent_blank"
      );
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="less">
.label {
  font-size: 0.8em;
  color: @secondary-color;
}

.box {
  padding: 1em;
  border: 1px solid @primary-color;
  border-radius: 4px;
}
.buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}
</style>
