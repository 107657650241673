<template>
  <div
    class="edu-section"
    :class="{ 'is-open': isOpen }">
    <div
      class="edu-section__header"
      @click="isOpen = !isOpen">
      <button
        type="button"
        class="edu-section__handler"
        :aria-label="
          isOpen
            ? 'Скрыть образовательный блок'
            : 'Показать образовательный блок'
        "
        :title="
          isOpen
            ? 'Скрыть образовательный блок'
            : 'Показать образовательный блок'
        "
        @click.stop="isOpen = !isOpen">
        <span></span>
      </button>
      <div class="text-size-h3 text-bold">Образовательный блок</div>
    </div>
    <div
      v-show="isOpen"
      class="edu-section__content">
      <div
        v-for="item in blocks"
        :key="item.id"
        class="edu-block">
        <div class="edu-block__img">
          <img
            v-if="item.img"
            :src="item.img"
            alt="" />
          <BaseIcon
            v-else
            glyph="education" />
        </div>
        <div class="edu-block__data">
          <div class="edu-block__info">
            <div class="text-bold text-size-m mb-xxs">{{ item.title }}</div>
            <div class="text-size-s">{{ item.description }}</div>
          </div>
          <div class="mt-s edu-block__foot">
            <a
              :href="item.url"
              target="_blank"
              class="link edu-block__link"
              rel="noopener noreferrer"
              @click.stop
              >Подробнее <BaseIcon glyph="external-link"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EducationBlocks",
  components: {},
  props: {
    /**
     * IBlock {
     *  id: number,
     *  img?: string
     *  published: boolean
     *  title: stirng,
     *  description: stirng
     *  roles?: string[],
     *  tracks?: string[],
     *  profiles?: number[]
     * }
     *
     * @type IBlock[]
     */
    blocks: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      isOpen: true,
    };
  },
};
</script>

<style scoped lang="less">
.block-link {
  margin-top: 10px;
}
.edu-section {
  background-color: #fff;
  padding: 12px;
  box-shadow: @shadow-default;
  border-radius: 12px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__handler {
    width: 32px;
    height: 32px;
    display: block;
    background-color: transparent;
    box-shadow: none;
    border: 0;
    outline: none;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;

    span {
      position: absolute;
      width: 12px;
      height: 12px;
      border: 1px solid;
      transform: rotateZ(-135deg);
      border-top: 0;
      border-left: 0;
      top: 50%;
      left: 50%;
      margin-top: -4px;
      margin-left: -6px;
      transition: color 0.3s, transform 0.3s;
      transform-origin: center center;
    }
  }

  &__content {
    padding-top: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px 10px;
  }

  &.is-open &__handler span {
    transform: rotateZ(45deg);
    margin-top: -6px;
  }
}

.edu-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  background-color: #fff;
  border: 1px solid @tropical-blue;
  padding: 12px;
  width: 49%;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 12px;
  align-items: stretch;
  position: relative;

  &__img {
    background-color: @tropical-blue;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 50%;
    margin-right: 10px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: @chambray;
    position: relative;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    svg {
      font-size: inherit;
    }
  }

  &__data {
    min-width: 1px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__info {
    flex-grow: 1;
  }

  &__foot {
    flex-grow: 0;
    // text-align: right;
  }

  &__link {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .edu-block {
    width: 100%;
  }
}
</style>
