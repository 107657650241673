<template>
  <div class="team-find">
    <BaseModalContent
      :dynamic="true"
      @close="$emit('close')">
      <p v-if="stageNumber === 1">Этап завершился!</p>
      <h2 class="text-bold text-size-h2 mb-s">
        Пожалуйста, оцени как твои наставники помогали тебе
      </h2>

      <p v-if="!mentorList.length">Нет наставников для оценки.</p>
      <p v-else-if="!profiles.length">Нет профилей для оценки</p>
      <div v-else>
        <p class="mb-m">
          Мы&nbsp;хотим проанализировать, насколько активно наставники НТО
          вовлечены на&nbsp;этом этапе. Пожалуйста, уделите нам пару минут
          и&nbsp;заполните форму опроса. Ваши ответы останутся анонимными.
        </p>
        <p class="mb-m">
          Для каждого наставника нужно указать профиль НТО и&nbsp;степень его
          вовлеченности. Если это ваш наставник по&nbsp;нескольким профилям,
          нажмите &laquo;Добавить профиль&raquo; и&nbsp;выберите нужный.
        </p>
        <p class="mb-l">
          Ваше участие в опросе будет очень полезно для НТО, но если вы все-таки
          не готовы заполнить форму, нажмите
          <a
            href="#"
            class="link link--pseudo"
            title="Не хочу принимать участие в опросе"
            @click.prevent="handleIgnoreRatings"
            >сюда</a
          >. Форма скроется. Обратите внимание — после этого вы не сможете
          принять участие в опросе, даже если передумаете.
        </p>

        <BaseLoadingBox :pending="pending">
          <ValidationObserver
            ref="form"
            tag="form"
            @submit.prevent="handleSubmit">
            <div
              v-for="mentor in mentorList"
              :key="mentor.id"
              class="mb-l">
              <MentorRatingCard
                :mentor="mentor"
                :profiles="profiles"
                :ratings="ratingData[mentor.talent_id]"
                @setRating="handleSetRating"
                @removeRating="handleRemoveRating"
                @setNonSupport="handleSetNonSupport" />
            </div>
            <div
              v-if="error"
              class="mb-m form-error">
              {{ error }}
            </div>
            <BaseButton type="submit">Отправить оценку</BaseButton>
          </ValidationObserver>
        </BaseLoadingBox>
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
import MentorRatingCard from "@/components/user/MentorRatingCard";
import findIndex from "lodash/findIndex";
import { request } from "@/services/api";
import { setCookie } from "@/utils/cookies";
import { IGNORE_MENTOR_RATINGS_PREFIX } from "@/constants";
export default {
  name: "MentorRating",
  components: {
    MentorRatingCard,
  },
  props: {
    stageNumber: {
      type: Number,
    },
    /**
     * Список наставников
     */
    mentors: {
      type: Array,
      required: true,
    },
    /**
     * Список профилей
     * [{id: number, title: string}]
     */
    profiles: {
      type: Array,
      required: true,
    },
    // @enum(INDIVIDUAL, TEAM, FINAL)
    stage: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ratingData: {},
      pending: false,
      error: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    mentorList() {
      if (!this.mentors) return [];
      return this.mentors.map((mentor) => {
        const fullName = [
          mentor.first_name,
          mentor.middle_name,
          mentor.last_name,
        ]
          .filter(Boolean)
          .join(" ");
        return {
          ...mentor,
          fullName,
        };
      });
    },
  },
  created() {
    this.init();
  },
  methods: {
    /**
     * Выставить или обновить оценку за профиль
     * @param {Object} payload - any
     * @param {Number} payload.profile - id профиля
     * @param {Number} payload.rating - оценка
     * @param {Number} payload.mentor - id Наставника
     */
    handleSetRating(payload) {
      const { ratingData } = this;
      const selected = ratingData[payload.mentor];
      if (!selected) return;
      selected.noSupport = false;
      const idx = findIndex(
        selected.ratings,
        (n) => n.profile === payload.profile
      );
      if (idx >= 0) {
        selected.ratings.splice(idx, 1, {
          profile: payload.profile,
          rating: payload.rating,
        });
      } else {
        selected.ratings.push({
          profile: payload.profile,
          rating: payload.rating,
        });
      }
    },
    /** Удалить оценку за профиль
     * @param {object} payload
     * @param {number} payload.profile
     * @param {number} payload.mentor
     */
    handleRemoveRating(payload) {
      const { ratingData } = this;
      const selected = ratingData[payload.mentor];
      if (!selected) return;
      const idx = findIndex(
        selected.ratings,
        (n) => n.profile === payload.profile
      );

      if (idx >= 0) {
        selected.ratings.splice(idx, 1);
      }
    },
    /**
     * Установить что ментор
     * не помогал в принципе
     * @param {Object} payload - any
     * @param {number} mentor - id негодяя
     * @param {boolean} status - статус
     */
    handleSetNonSupport(payload) {
      const { ratingData } = this;
      const selected = ratingData[payload.mentor];
      if (!selected) return;
      selected.noSupport = Boolean(payload.status);
    },
    /**
     * Сохраняем оценки
     */
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (this.pending) return;
      this.pending = true;
      this.error = "";
      if (!isValid) {
        this.error = "Исправьте ошибки в форме и повторите попытку.";
        this.pending = false;
        return;
      }

      const result = Object.values(this.ratingData).reduce((acc, value) => {
        // Если ментор не помогал, то надо все профили отметить
        // оценочкой равной нолику
        if (value.noSupport) {
          this.profiles.forEach((profile) => {
            acc.push({
              mentor: value.mentor,
              profile: profile.id,
              stage: this.stage,
              rating: 0,
            });
          });
        } else {
          // Если все таки помогал, то скажем где и как сильно
          value.ratings.forEach((rating) => {
            acc.push({
              mentor: value.mentor,
              profile: rating.profile,
              stage: this.stage,
              rating: rating.rating,
            });
          });
        }

        return acc;
      }, []);
      if (result.length) {
        try {
          const requests = result.map((data) =>
            request({
              method: "POST",
              url: "/mentor/ratings",
              data,
            })
          );
          await Promise.all(requests);
          this.$emit("close");
        } catch (error) {
          this.error = error.message;
        }
        this.pending = false;
      }
    },
    /**
     * Нужно создать под каждого ментора
     * объект с оценками
     */
    init() {
      const ratingData = {};
      this.mentors.forEach((mentor) => {
        ratingData[mentor.talent_id] = {
          mentor: mentor.talent_id,
          ratings: [],
          noSupport: false,
        };
      });
      this.ratingData = ratingData;
    },
    /**
     * Больше не показывать модаку про
     * оценку менторов
     */
    handleIgnoreRatings() {
      const name = `${IGNORE_MENTOR_RATINGS_PREFIX}${this.user.talent_id}`;
      // вешаем куку на 365 дней
      setCookie(name, 1, 365);
      this.$emit("close");
      window.dataLayer?.push({
        event: "ntoProfileEvent",
        eventCategory: "nto_profile",
        eventAction: "ignore_mentor_ratings",
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
