<template>
  <div class="achievement-card">
    <div class="achievement-card__icon">
      <BaseImage
        v-if="achievement.profile_icon"
        :src="achievement.profile_icon"
        :aspect-ratio="1" />
      <BaseImage
        v-else
        :aspect-ratio="1"
        src="@/assets/images/profile_placeholder.svg" />
    </div>
    <div class="achievement-card__data">
      <div class="achievement-card__info">
        <div class="color-meta text-size-s">{{ achievement.stage }}</div>
        <div class="text-bold text-size-l mb-s">
          {{ achievement.profile_title }}
        </div>
      </div>
      <div class="achievement-card__controls text-size-m">
        <div class="achievement-card__role">
          <div class="achievement-card__role-icon">
            <img
              :src="icon"
              alt="Иконка достижения" />
          </div>
          <span class="achievement-card__role-text">{{
            achievement.role
          }}</span>
        </div>

        <a
          v-if="achievement.diploma_link"
          href="#"
          class="link"
          download
          @click="handleClickDownload"
          >Скачать диплом&nbsp;<BaseIcon
            class="achievement-card__download"
            glyph="link"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import handIcon from "@/assets/images/achievements/hand.svg";
import cupIcon from "@/assets/images/achievements/cup.svg";
import starIcon from "@/assets/images/achievements/star.svg";

export default {
  name: "AchievementCard",
  props: {
    achievement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      const id = this.achievement.role_id;
      switch (+id) {
        case 3:
        case 14:
        case 4:
        case 7:
          return starIcon;
        case 6:
        case 19:
          return cupIcon;
        default:
          return handIcon;
      }
    },
  },
  methods: {
    handleClickDownload(e) {
      e.preventDefault();
      const age = this.$store.getters["user/age"];
      const user = this.$store.getters["user/user"];
      if (age < 18 && !user.adult_confirmed) {
        this.showErrorModal({
          content: `Скачать диплом возможно только с проверенным файлом согласия. Пожалуйста, <a href="${process.env.VUE_APP_TALENT_BASE_URL}/consent_file" class="link">загрузите правильный файл согласия</a>. После проверки вы сможете скачать диплом.`,
        });
        window.dataLayer?.push({
          event: "ntoProfileEvent",
          eventCategory: "nto_profile",
          eventAction: "download_diploma_consent_error",
          userProfile: this.achievement.profile_title,
          achievementRole: this.achievement.role,
        });
        return;
      }
      window.dataLayer?.push({
        event: "ntoProfileEvent",
        eventCategory: "nto_profile",
        eventAction: "download_diploma",
        userProfile: this.achievement.profile_title,
        achievementRole: this.achievement.role,
      });
      window.open(this.achievement.diploma_link, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.achievement-card {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  background-color: #fff;
  padding: 16px 13px;
  height: 100%;
  border-radius: @radius-m;
  box-shadow: @shadow-default;

  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 13px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__data {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  &__info {
    flex-grow: 1;
    width: 100%;
  }

  &__download {
    margin-top: -0.25em;
  }

  &__role {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    background-color: @light-gray;
    color: @link-color;
    font-weight: bold;
    border-radius: 1em;
    padding: 3px 1em 3px 3px;
    margin-right: 1em;

    &-icon {
      flex-grow: 0;
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      background-color: #fff;
      box-shadow: 0px 0px 1.59871px #cfd8fa;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 0.45em;

      img {
        max-width: 90%;
      }
    }

    &-text {
      flex-grow: 1;
      white-space: nowrap;
    }
  }

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 0;
    width: 100%;
    flex-wrap: wrap;
  }
}
</style>
