<template>
  <div
    v-if="isVisible"
    class="panel radius-xl">
    <div
      v-if="individualResults.visible"
      class="stage-section">
      <h3 class="text-size-h3 text-bold mb-s">Первый этап завершен!</h3>
      <div class="mt-m">
        <div
          v-for="profile in individualResults.results"
          :key="profile.id"
          :class="profile.status"
          class="stage-result">
          <div class="stage-result__icon">
            <BaseIcon
              :glyph="
                profile.status === WIN_PROFILE
                  ? 'done'
                  : profile.status === FAILED_PROFILE
                  ? 'warn'
                  : 'waiting'
              " />
          </div>
          <div class="stage-result__data">
            <div
              v-if="!profileId"
              class="text-bold mb-xxs">
              {{ profile.title }}
            </div>
            <div>
              <div v-if="profile.status === 'win'">
                <div v-if="isJunior">
                  <div>Поздравляем, вы прошли в финал по этой сфере!</div>
                  <BaseButton
                    v-if="$route.name !== 'user-profile-stage-2'"
                    tag="router-link"
                    class="mt-s"
                    size="sm"
                    :to="{
                      name: 'user-profile-stage-2',
                      params: { profile_id: profileId },
                    }"
                    >Перейти к финальному этапу</BaseButton
                  >
                </div>
                <div v-else>
                  <div>
                    Поздравляем, вы прошли во второй этап по этому профилю!<br />
                    Собирайте команду и готовьтесь вместе решать задания, чтобы
                    выйти в финал!
                  </div>

                  <BaseButton
                    v-if="$route.name !== 'user-profile-stage-2'"
                    tag="router-link"
                    size="sm"
                    class="mt-s"
                    :to="{
                      name: 'user-profile-stage-2',
                      params: { profile_id: profile.profile_id },
                    }"
                    >Перейти ко II этапу</BaseButton
                  >
                </div>
              </div>
              <div v-else-if="profile.status === 'failed'">
                К&nbsp;сожалению, этих баллов недостаточно, чтобы пройти
                в&nbsp;следующий этап. Но&nbsp;мы&nbsp;надеемся, что вы&nbsp;все
                равно получили полезный опыт и&nbsp;поняли, какие темы
                и&nbsp;предметы вам стоит изучить глубже. <br />
                Желаем успехов и&nbsp;ждем новой встречи в&nbsp;следующем году!
              </div>
              <div v-else>
                Мы&nbsp;подводим итоги индивидуального этапа, через некоторое
                время результаты будут опубликованы.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="teamResults.visible"
      class="stage-section">
      <h3 class="text-size-h3 text-bold mb-s">
        {{ isStudent ? "Первый" : "Второй" }} этап завершен!
      </h3>
      <div class="mt-m">
        <div
          v-for="profile in teamResults.results"
          :key="profile.id"
          :class="profile.status"
          class="stage-result">
          <div class="stage-result__icon">
            <BaseIcon
              :glyph="
                profile.status === WIN_PROFILE
                  ? 'done'
                  : profile.status === FAILED_PROFILE
                  ? 'warn'
                  : 'waiting'
              " />
          </div>
          <div class="stage-result__data">
            <div
              v-if="!profileId"
              class="text-bold mb-xxs">
              {{ profile.title }}
            </div>
            <div>
              <div v-if="profile.status === 'win'">
                <div>Поздравляем, вы прошли в финал по этому профилю!</div>
                <BaseButton
                  v-if="$route.name !== finalRouteName"
                  tag="router-link"
                  class="mt-s"
                  size="sm"
                  :to="{
                    name: finalRouteName,
                    params: { profile_id: profile.profile_id },
                  }"
                  >Перейти к финальному этапу</BaseButton
                >
              </div>
              <div v-else-if="profile.status === 'failed'">
                К&nbsp;сожалению, этих баллов недостаточно, чтобы пройти
                в&nbsp;следующий этап. Но&nbsp;мы&nbsp;надеемся, что вы&nbsp;все
                равно получили полезный опыт и&nbsp;поняли, какие темы
                и&nbsp;предметы вам стоит изучить глубже. <br />
                Желаем успехов и&nbsp;ждем новой встречи в&nbsp;следующем году!
              </div>
              <div v-else>
                Мы&nbsp;подводим итоги командного этапа, через некоторое время
                результаты будут опубликованы.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="failedFinals.length">
      <h3 class="text-size-h3 text-bold mb-s">Финальный этап завершен!</h3>
      <div class="mt-m">
        <div
          v-for="profile in failedFinals"
          :key="profile.id"
          :class="FAILED_PROFILE"
          class="stage-result">
          <div class="stage-result__icon">
            <BaseIcon glyph="warn" />
          </div>
          <div class="stage-result__data">
            <div
              v-if="!profileId"
              class="text-bold mb-xxs">
              {{ profile.title }}
            </div>
            <div>
              <div>
                К&nbsp;сожалению, этих баллов недостаточно.
                Но&nbsp;мы&nbsp;надеемся, что вы&nbsp;все равно получили
                полезный опыт и&nbsp;поняли, какие темы и&nbsp;предметы вам
                стоит изучить глубже. <br />
                Желаем успехов и&nbsp;ждем новой встречи в&nbsp;следующем году!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="finalResults.length"
      class="stage-section">
      <h3 class="text-size-h3 text-bold mb-m">
        <span>Достижения заключительного этапа:</span>
      </h3>
      <div class="row">
        <div
          v-for="achievement in finalResults"
          :key="achievement.id"
          class="col-md-6 col-xs-12 mb-m">
          <AchievementCard :achievement="achievement" />
        </div>

        <div
          v-if="finalResults.length % 2 !== 0"
          class="col-md-6 col-xs-12 mb-m">
          <router-link
            class="all-achievements"
            :to="{ name: 'user-achievements' }">
            <div>
              <div>
                <BaseIcon
                  class="icon"
                  glyph="file-pdf" />
              </div>
              <span class="text-bold text-size-l"
                >Все достижения сезона <BaseIcon glyph="arrow-right"
              /></span>
            </div>
          </router-link>
        </div>
      </div>
      <div v-if="finalResults.length % 2 === 0">
        <router-link
          class="link"
          :to="{ name: 'user-achievements' }">
          Все достижения сезона <BaseIcon glyph="arrow-right" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
import { FINAL_STAGE, INDIVIDUAL_STAGE, TEAM_STAGE } from "@/constants";
import { getMaxDate, getMinDate } from "@/utils";
import AchievementCard from "@/components/user/AchievementCard";

const INDIVIDUAL_TEAM_TRANSFER = "INSIVIDUAL_TEAM_TRANSFER";
const TEAM_FINAL_TRANSFER = "TEAM_FINAL_TRANSFER";
const FAILED_PROFILE = "failed";
const WIN_PROFILE = "win";
const PENDING_PROFILE = "pending";
const VISIBILITY_TRANSFER_DATE_SHIFT = 60 * 60 * 24 * 7; // 5 дней
/**
 * Возвращает дату начала и дату окончания этапа трансфера
 * @param {any[]} profiles
 * @param {number} track
 * @param {string} stage
 * @returns {{start?: string, end?: string }} range
 */
const getDateRange = (
  profiles,
  track,
  stage,
  schedule = [],
  profileSchedule = {}
) => {
  const globalSchedule = schedule?.find(
    (n) => n.stage === stage && n.track?.id === track
  );
  const result = {
    start: [globalSchedule?.start_at],
    end: [globalSchedule?.end_at],
  };

  profiles.forEach((profile) => {
    const pSchedule = profileSchedule[profile.profile_id]?.find((n) => {
      return n.stage === stage && n.track?.id === track;
    });
    if (profiles.length === 1 && pSchedule) {
      // если профиль 1 и есть специфика - то берем ее
      result.start = [pSchedule.start_at];
      result.end = [pSchedule.end_at];
    } else if (pSchedule) {
      result.start.push(pSchedule.start_at);
      result.end.push(pSchedule.end_at);
    }
  });
  return {
    start: getMinDate(result.start),
    end: getMaxDate(result.end),
  };
};

export default {
  name: "StageStatusPanel",
  components: {
    AchievementCard,
  },
  props: {
    profileId: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      firstStage: "participant/firstStage",
      secondStage: "participant/secondStage",
      thirdStage: "participant/thirdStage",
      selectedList: "participant/selectedList",
      isJunior: "participant/isJunior",
      is4GradeJunior: "participant/is4GradeJunior",
      isStudent: "participant/isStudent",
    }),
    finalResults() {
      const { finalProfiles } = this;
      if (!finalProfiles.length) return [];
      const achievements = this.$store.state.participant.finalAchievements;
      return finalProfiles.reduce((acc, value) => {
        const list = achievements[value.profile_id];
        if (list?.length) {
          acc = [
            ...acc,
            ...list.map((a) => {
              return {
                id: a.id,
                profile_title: value.title,
                stage: "Финал",
                role: a.role.name,
                diploma_link: a.diploma_link,
                profile_icon: value.profile_icon,
              };
            }),
          ];
        }
        return acc;
      }, []);
    },
    failedFinals() {
      return this.finalProfiles.filter((n) => n.failed);
    },
    finalRouteName() {
      return this.isStudent ? "user-profile-stage-2" : "user-profile-stage-3";
    },
    userTrack() {
      return this.$store.state.participant.track?.id;
    },
    profiles() {
      return this.$store.state.profile.profiles;
    },
    userProfiles() {
      const userProfiles =
        Object.values(this.$store.state.participant.profiles) || [];
      const list = userProfiles.map((n) => {
        return {
          ...n,
          title: this.profiles[n.profile_id]?.title,
          profile_icon: this.profiles[n.profile_id]?.icon,
        };
      });

      /**
       * Если передан конкретный профиль -
       * то нужно вывести данные только о нем.
       */
      if (this.profileId) {
        return list.filter((n) => n.profile_id === this.profileId);
      }
      return list;
    },
    isVisible() {
      const { is4GradeJunior, individualResults, teamResults } = this;
      if (is4GradeJunior) return false;
      return (
        individualResults.visible ||
        teamResults.visible ||
        this.finalResults?.length ||
        this.failedFinals?.length
      );
    },
    individualProfiles() {
      return this.userProfiles.filter(
        (n) => n.stage === INDIVIDUAL_STAGE && !n.rejected
      );
    },
    teamProfiles() {
      return this.userProfiles.filter(
        (n) => n.stage === TEAM_STAGE && !n.rejected
      );
    },
    finalProfiles() {
      return this.userProfiles.filter(
        (n) => n.stage === FINAL_STAGE && !n.rejected
      );
    },
    individualDatesRange() {
      const { individualProfiles, userTrack } = this;
      return getDateRange(
        individualProfiles,
        userTrack,
        INDIVIDUAL_TEAM_TRANSFER,
        this.$store.state.schedule,
        this.$store.state.profile.schedule
      );
    },
    teamDatesRange() {
      const { teamProfiles, userTrack } = this;
      return getDateRange(
        teamProfiles,
        userTrack,
        TEAM_FINAL_TRANSFER,
        this.$store.state.schedule,
        this.$store.state.profile.schedule
      );
    },
    /**
     * Результаты за индивидуальный этап
     * треки: вся школа
     * @returns {{visible: boolean, results: {title: string, status: string, profile_id: number}[]}}
     */
    individualResults() {
      const {
        individualProfiles,
        teamProfiles,
        finalProfiles,
        individualDatesRange,
      } = this;
      const nextProfiles = [...teamProfiles, ...finalProfiles];
      const result = {
        visible: false,
        results: [],
      };
      if (!individualProfiles.length) return result;

      result.results = individualProfiles.map((profile) => {
        let status = profile.failed ? FAILED_PROFILE : PENDING_PROFILE;

        if (nextProfiles.some((n) => n.profile_id === profile.profile_id)) {
          /**
           * Если есть командный или финальный этап по этому профилю -
           * то считаем win
           */
          status = WIN_PROFILE;
        }
        return {
          ...profile,
          status,
        };
      });

      if (this.profileId && this.finalResults?.length) {
        return result;
      }

      if (result.results.every((n) => n.status === FAILED_PROFILE)) {
        /**
         * Если все профили провалены - то этот статус всегда должен
         * выводиться
         */
        result.visible = true;
        return result;
      }

      if (!nextProfiles?.length && individualDatesRange.start) {
        /**
         * Если нет следующих этапов, то всегда выводим
         * если дата трансфера началась
         */
        result.visible =
          Date.now() > new Date(individualDatesRange.start).getTime();
      } else if (nextProfiles?.length && individualDatesRange.end) {
        // если есть следующие этапы и дата окончания трансфера
        result.visible =
          Date.now() - new Date(individualDatesRange.end) <
          VISIBILITY_TRANSFER_DATE_SHIFT;
      }
      return result;
    },

    /**
     * Результаты командного этапа
     * треки: школа кроме джунов, студенты
     */
    teamResults() {
      const { teamProfiles, finalProfiles, teamDatesRange } = this;
      const result = {
        visible: false,
        results: [],
      };
      if (!teamProfiles.length) return result;
      if (
        (this.profileId && this.finalResults?.length) ||
        teamProfiles.length === this.finalResults?.length
      ) {
        return result;
      }
      result.results = teamProfiles.map((profile) => {
        let status = profile.failed ? FAILED_PROFILE : PENDING_PROFILE;
        if (finalProfiles.some((n) => n.profile_id === profile.profile_id)) {
          /**
           * Если есть финальный этап по этому профилю -
           * то считаем win
           */
          status = WIN_PROFILE;
        }
        return {
          ...profile,
          status,
        };
      });

      if (result.results.every((n) => n.status === FAILED_PROFILE)) {
        /**
         * Если все профили провалены - то этот статус всегда должен
         * выводиться
         */
        result.visible = true;
        return result;
      }

      if (!finalProfiles?.length && teamDatesRange.start) {
        /**
         * Если нет следующих этапов, то всегда выводим
         * если дата трансфера началась
         */
        result.visible = Date.now() > new Date(teamDatesRange.start).getTime();
      } else if (teamProfiles?.length && teamDatesRange.end) {
        // если есть следующие этапы и дата окончания трансфера
        result.visible =
          Date.now() - new Date(teamDatesRange.end) <
          VISIBILITY_TRANSFER_DATE_SHIFT;
      }
      return result;
    },
  },
  created() {
    this.FAILED_PROFILE = FAILED_PROFILE;
    this.WIN_PROFILE = WIN_PROFILE;
    this.PENDING_PROFILE = PENDING_PROFILE;
  },
  mounted() {
    if (this.finalProfiles.length) {
      this.$store.dispatch("participant/getFinalAchievements");
    }
  },
};
</script>

<style lang="less" scoped>
.all-achievements {
  background-color: #fff;
  padding: 16px 13px;
  min-height: 100%;
  border-radius: @radius-m;
  box-shadow: @shadow-default;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  color: @base-color;

  .icon {
    font-size: 28px;
    margin-bottom: 0.3em;
  }

  &:hover {
    color: @link-color;
    box-shadow: @shadow-hover;
  }
}
.stage-section {
  & + & {
    margin-top: 30px;
  }
}
.stage-result {
  display: flex;
  flex-flow: row nowrap;
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;

  & + & {
    margin-top: 20px;
  }

  &:last-child {
    margin-top: 0 !important;
    padding-bottom: 0;
    border-bottom: 0;
  }

  &__icon {
    flex-grow: 0;
    flex-shrink: 0;
    width: 32px;
    color: lighten(@link-color, 20%);
    margin-right: 10px;
    font-size: 26px;
    opacity: 0.75;
  }

  &.failed &__icon {
    color: @error-color;
  }
  &.win &__icon {
    color: @success-color;
  }

  &__data {
    flex-grow: 0;
    align-self: center;
  }
}
</style>
